<template>
    <div class="card-column">
        <router-link :to="{ name: 'Produs', params: { slug: product.slug } }">
            <div class="product-card">
                <div class="image">
                    <img
                        loading="lazy"
                        width="240"
                        height="160"
                        :alt="product.name"
                        :src="product.thumbnails['h-240']"
                        :srcset="
                            product.thumbnails['h-240'] +
                                ' 420w, ' +
                                product.thumbnails['h-160'] +
                                ' 768w, ' +
                                product.thumbnails['h-240'] +
                                ' 2440w, '"
                    >
                </div>
                <p class="sbold">
                    {{ product.category }}
                </p>
                <div class="title">
                    <h4>{{ product.name }}</h4>
                    <ArrowRightIcon />
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
    import ArrowRightIcon from 'vue-feather-icons/icons/ArrowRightIcon';
    export default {
        name: 'BestSellerCard',
        components: {
            ArrowRightIcon,
        },
        props: {
            product: {
                type: Object,
                required: true,
            },
        },
    };
</script>
<style scoped lang="scss">
.card-column {
  @include media-breakpoint-up(xm) {
    width: calc((100% - 24px) / 3);
  }
  @include media-breakpoint-up(md) {
    width: calc((100% - 80px) / 3);
  }

  .product-card {
    padding: 24px;
    border-radius: 8px;
    transition: box-shadow 300ms ease-in-out;
    @include media-breakpoint-between(xm, sm) {
      padding: 12px;
    }
    .sbold {
      color: $text;
      @include media-breakpoint-between(xm, sm) {
        font-size: 13px;
      }
    }
    .title {
      margin-top: 4px;
      position: relative;
      h4 {
        height: 60px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-align: end;
        margin-right: 46px;
        width: calc(100% - 62px);
        text-transform: capitalize;
        font-size: 25px;
        font-weight: 700;
        color: $black;
        @include media-breakpoint-down(lg) {
          font-size: 20px;
        }
        @include media-breakpoint-down(lg) {
          height: 48px;
          margin-right: 26px;
          width: calc(100% - 26px);
        }
        @include media-breakpoint-between(xm, sm) {
          height: 36px;
          font-size: 15px;
          margin-right: 20px;
          width: calc(100% - 20px);
        }
      }
      svg {
        height: 16px;
        width: 16px;
        position: absolute;
        right: 0;
        bottom: 5px;
        transition: right 300ms ease-in-out;
      }
    }
    .image {
      text-align: center;
      margin-bottom: 40px;
      aspect-ratio: 3 / 2;
      width: 100%;
      overflow: hidden;
      @include media-breakpoint-between(xm, sm) {
        margin: 0 auto 24px;
      }
       @include media-breakpoint-down(xm) {
        margin: 0 auto 40px;
      }
      max-height: 240px;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    @include media-breakpoint-up(md) {
      &:hover {
        box-shadow: $shadow-menu;
        .title {
          svg {
            right: -5px;
          }
        }
      }
    }
  }
  &:nth-child(1) {
    a {
      > div {
        background-color: $gray-light;
      }
    }
  }
  &:nth-child(2) {
    a {
      > div {
        background-color: $primary-25;
      }
    }
  }
  &:nth-child(3) {
    a {
      > div {
        background-color: $secondary-25;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }
}
</style>
