<template>
    <div class="container">
        <div class="heading">
            <h3>{{ $t('page.home.bestSellersHeading') }}</h3>
            <router-link
                v-slot="{ navigate }"
                :to="{ path: '/categorie/naluci/voblere?manufacturers=Duo' }"
                custom
            >
                <b-button
                    variant="text"
                    class="stroke lg"
                    @click="navigate"
                >
                    {{ $t('page.home.bestSellersButton') }}
                </b-button>
            </router-link>
        </div>
        <div
            v-if="existData"
            class="cards"
        >
            <best-seller-card
                v-for="product in bestsellers"
                :key="`bestseller-${bestsellers.indexOf(product)}`"
                :product="product"
            />
        </div>
        <b-row v-if="loading && !existData">
            <b-col
                v-for="index in 3"
                :key="`loader-${index}`"
                cols="12"
                md="4"
                sm="4"
                col
            >
                <home-best-sellers-loader />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import BestSellerCard from '@/components/home/BestSellerCard';
    import HomeBestSellersLoader from '@/components/loaders/home/HomeBestSellersLoader';
    export default {
        name: 'HomeBestSellers',
        components: {
            BestSellerCard,
            HomeBestSellersLoader,
        },
        computed: {
            ...mapState('home', ['bestsellers', 'loading']),
            existData() {
                return this.bestsellers.length > 0;
            },
        },
    };
</script>

<style scoped lang="scss">
.best-sellers {
  .container {
    .heading {
      h3 {
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
          margin-bottom: 24px;
        }
      }
    }
    .row,
    .cards {
      @include media-breakpoint-down(sm) {
        margin-top: 24px;
      }
    }
    .cards {
      display: flex;
      gap: 40px;
      @include media-breakpoint-down(md) {
        gap: 12px;
      }
      @include media-breakpoint-down(xm) {
        flex-direction: column;
      }
      .card-column {
        flex: 1;
      }
    }
  }
}
</style>
